import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

interface StyledButtonProps {
  loading?: boolean
  maxWidth?: boolean
}

const StyledButton = styled(Button)<StyledButtonProps>(({ loading, theme, fullWidth }) => ({
  backgroundColor: loading ? theme.palette.secondary.light : theme.palette.primary.main,
  marginTop: '20px',
  maxWidth: fullWidth ? '100%' : '300px',
}))

interface ButtonProps {
  loading?: boolean
  text: string
  handleClick?: () => void
  disabled?: boolean
}
export const ButtonLoader = ({ loading, text, handleClick, disabled = false }: ButtonProps) => {
  return (
    <StyledButton
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleClick}
      disabled={loading || disabled}
      fullWidth
    >
      {loading && (
        <CircularProgress
          size="1.3rem"
          sx={{
            position: 'absolute',
          }}
        />
      )}
      <Box component="span" ml={2}>
        {text}
      </Box>
    </StyledButton>
  )
}
