import Head from 'next/head'
import { FC } from 'react'

interface MetaProps {
  title: string
  description: string
}

const Meta: FC<MetaProps> = ({ title, description }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Head>
    // TODO: Add GTM tag and other scripts..
  )
}

export default Meta
