import { LangType, ProductBookingData, ProductKey, ProductName, Slot } from 'types'
import { datesAreHourApart } from './dates'
import config from '../../config'

export const calculateTotalPrice = (groupSize: number, selectedSlot: Slot): number => {
  // @ts-ignore
  return Math.ceil((selectedSlot?.pricing[groupSize] ?? 0) * groupSize)
}

// Takes free slots and creates a new array that always containes at least 2 free slots next to each other
export const filteredDoubleSlots = (slots: Slot[]): Slot[] => {
  return slots.map((obj: Slot, index: number) => {
    const previousObj: Slot | undefined = slots[index - 1]
    const nextObj: Slot | undefined = slots[index + 1]

    if (
      (previousObj === undefined || previousObj.free === false) &&
      nextObj &&
      nextObj.free === false
    ) {
      return {
        ...obj,
        free: false,
      }
    }

    return obj
  })
}
export const getAdjacentSlot = (
  slots: Slot[],
  currentSlotIndex: number,
  adjacentSlot: 'prev' | 'next'
): Slot => {
  const offset = adjacentSlot === 'prev' ? -1 : 1
  return slots[currentSlotIndex + offset]
}

export const getSlotDatesOfOtherProduct = (
  selectedProducts: ProductKey[] | [],
  product: ProductKey,
  products: Record<ProductKey, ProductBookingData>
) => {
  const otherProduct = selectedProducts?.filter(productType => productType !== product)
  // If so, check if the other product has slots selected
  return products[otherProduct[0]]?.selectedSlots
}

export const ProductNameLocalized: {
  [key: string]: {
    cs: string
    en: string
  }
} = {
  DUNGEON: {
    cs: 'Hororové podzemí',
    en: 'Dungeon of Horror',
  },
  EXPERIMENT: {
    cs: 'Experiment',
    en: 'The Experiment',
  },
  COMBO: {
    cs: 'Adrenalin pack',
    en: 'The Adrenalin Pack',
  },
}

export const createProductName = (
  products: ProductName[] | ProductName,
  locale: string
): string => {
  // TODO: fix type
  if (typeof products === 'string') return ProductNameLocalized[products][locale]

  if (products.length > 1) {
    return ProductNameLocalized['COMBO'][locale]
  }
  return ProductNameLocalized[products[0]][locale]
}

export const checkDepositRequirement = (depositRequirement: string) => {
  return depositRequirement === 'required'
}

export const checkSoftDepositRequirement = (
  depositRequirement: string,
  isLocalCustomer: boolean,
  isVoucher: boolean
) => {
  return depositRequirement === 'soft' && !isLocalCustomer && !isVoucher
}

export const getCurrency = (isLocalCustomer: boolean) => (isLocalCustomer ? 'Kč' : 'CZK')

export const createCancelLink = (bookingId: string, lang: LangType) => {
  const cancelLinkText =
    lang === 'cs' ? 'PRO ZRUŠENÍ REZERVACE KLIKNĚTE ZDE' : 'CLICK HERE TO CANCEL'
  return `<a href="https://thrillpark-booking.vercel.app/booking?id=${bookingId}">[${cancelLinkText}]</a>`
}

export const productsDifferInDate = (
  products: Record<ProductKey, ProductBookingData>,
  product: ProductKey
) => {
  const secondProduct = product === 'DUNGEON' ? 'EXPERIMENT' : 'DUNGEON'
  // LESSON: You cant compare date object directly cause its a reference problem
  const secondProductDate = products[secondProduct].datePickerDate.getTime()
  const firstProductDate = products[product].datePickerDate.getTime()

  return secondProductDate !== firstProductDate
}
