import { FC } from 'react'
import Navigation from './Navigation'
import Meta from './Meta'

interface LayoutProps {
  title: string
  description: string
}

const PageLayout: FC<LayoutProps> = ({ children, title, description }) => {
  return (
    <>
      {/* TODO: add header  */}
      <Meta title={title} description={description} />
      <Navigation />
      {children}
      {/* TODO: add footer */}
    </>
  )
}

export default PageLayout
