import { BookingInformation, BookingPayload } from '@/types/index'
import axios from 'axios'

export const fetcher = async (
  // TODO: ideally refactor this fetcher and stard using something else, this gets messy
  key: string,
  bookingPayload?: BookingPayload | Partial<BookingPayload>,
  bookingId?: string,
  bookingInformation?: BookingInformation[]
): Promise<any> => {
  switch (key) {
    case 'freeSlots':
      return axios.post('/api/booking/getFreeSlots', {
        bookingPayload,
      })
    case 'bookingRequest':
      return axios.post('/api/booking/bookingRequest', bookingPayload)
    case 'createReservation':
      return axios.post('/api/booking/createReservation', {
        bookingPayload,
      })
    case 'getValidVouchers':
      return axios.post('/api/booking/getValidaVouchers', { bookingPayload })
    case 'cancelBooking':
      return axios.post('/api/booking/cancelBooking', { bookingId })
    case 'manualBooking':
      return axios.post('/api/booking/manualBooking', { bookingPayload, bookingId })
    case 'getPaymentLink':
      return axios.post('/api/booking/getPaymentLink', { bookingInformation })
    default:
      break
  }
}
