import * as React from 'react'
import Link from 'next/link'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import { useRouter } from 'next/router'
import Mask from '../../../public/images/mask_icon_white.svg'
import LocaleSwitcher from './LocaleSwitcher'

const Navigation = () => {

  const router = useRouter()
  const { locale } = router
  // const styles =
  //   state.product === 'DUNGEON'
  //     ? { backgroundColor: 'primary.main' }
  //     : { backgroundColor: 'success.main' }

  // TODO: add to envs
  const homeLink = locale === 'en' ? 'https://www.thrillparkprague.com' : `https://thrillpark.cz`

  return (
    <Box>
      {/* sx={styles} */}
      <AppBar position="static" >
        <Box
          sx={{
            backgroundColor: 'primary.main',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 20px',
          }}
        >
          <Link href={homeLink}>
            <SvgIcon>
              <Mask />
            </SvgIcon>
          </Link>
          <LocaleSwitcher />
        </Box>
      </AppBar>
    </Box>
  )
}

export default Navigation
