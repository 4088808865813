import { DepositType } from "./src/types"

export default {
  DUNGEON: {
    CALENDAR_ID: process.env.DUNGEON_CALENDAR_ID,
    BOOKING_BUFFER: Number(process.env.BOOKING_BUFFER),
    DEFAULT_SLOT_DURATION: process.env.DUNGEON_SLOT_DURATION,
    DUNGEON_SLOT_DURATION_SHORT: process.env.DUNGEON_SLOT_DURATION_SHORT,
    PRICING_WEEKEND: process.env.DUNGEON_PRICING_WEEKEND,
    PRICING_DEFAULT: process.env.DUNGEON_PRICING_DEFAULT,
    // This won't be used, i just wanted to have the same object structure
    PRICING_COMBO: process.env.DUNGEON_PRICING_DEFAULT,
    DEFAULT_STARTING_TIME: process.env.DUNGEON_DEFAULT_STARTING_TIME,
    MAX_GROUP_SIZE: 10,
    MULTIPLE_SLOT_TRESHOLD: 6,
  },
  EXPERIMENT: {
    CALENDAR_ID: process.env.EXPERIMENT_CALENDAR_ID,
    PRICING_WEEKEND: process.env.EXPERIMENT_PRICING_WEEKEND,
    PRICING_DEFAULT: process.env.EXPERIMENT_PRICING_DEFAULT,
    PRICING_COMBO: process.env.EXPERIMENT_PRICING_COMBO,
    DUNGEON_SLOT_DURATION_SHORT: null,
    DEFAULT_SLOT_DURATION: Number(process.env.EXPERIMENT_SLOT_DURATION),
    DEFAULT_STARTING_TIME: process.env.EXPERIMENT_DEFAULT_STARTING_TIME,
    BOOKING_BUFFER: process.env.BOOKING_BUFFER,
    MAX_GROUP_SIZE: 10,
    MULTIPLE_SLOT_TRESHOLD: 6,
  },
}

export const DEFAULT_DEPOSIT = 500

export const defaultCalendarConfig = {
  deposit: 'false' as DepositType,
  pricing: 'weekday',
  minGroupSize: 2,
  increasedCapacity: 'false',
}
