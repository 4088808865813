import { useRouter } from 'next/router'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { ButtonGroup, Button } from '@mui/material'
import ReactCountryFlag from 'react-country-flag'

const LocaleSwitcher = () => {
  const router = useRouter()
  const { locales, pathname, asPath, locale } = useRouter()

  const [lang, setLang] = useState(locale)

  const handleChange = (event: any) => {
    const language = event.currentTarget.value

    setLang(language)

    router.push(router.pathname, router.pathname, { locale: language });
  }

  const flagStyles = {
    width: '1.5em',
    height: '1.5em',
  }

  const createIcon = (locale: string) => {
    switch (locale) {
      case 'en':
        return <ReactCountryFlag countryCode="GB" svg title="English" style={flagStyles} />
      case 'cs':
        return <ReactCountryFlag countryCode="CZ" svg title="Czech" style={flagStyles} />
      default:
        return null
    }
  }

  return (
    <ButtonGroup variant="contained" aria-label="outlined primary button group">
      {locales?.map(local => (
        <Button onClick={handleChange} value={local} key={nanoid()}>
          {createIcon(local)}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default LocaleSwitcher
