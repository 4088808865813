import { useRouter } from 'next/router'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useAppStore } from './useStore'

export const useGtm = (url: string) => {
  const { locale } = useRouter()
  const product = useAppStore(state => state.selectedProducts)

  useEffect(() => {
    product.forEach(product => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          url,
          product,
          locale,
        },
      })
    })
  }, [locale])
}
