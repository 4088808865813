import { Box} from '@mui/material'

interface Props {
  text: string
}

export const BloodText = ({ text }: Props) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        // TODO: replace by svg from the assets
        backgroundImage: `url(https://thrillparkprague.com/resources/img/Icons/tp_web2018_blood_icon_01.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'inline-block',
      }}
    >
      {text}
    </Box>
  )
}
